<template>
  <div class="box">
    <Breadcrumb class="navigation">
      <BreadcrumbItem v-for="item in breadMenuData"
        :to="item.path">
        {{ item.name }}
      </BreadcrumbItem>
    </Breadcrumb>
    <div class="business-introduce-box">
      <p class="tit">1. {{ $t('key1005272') }}</p>
      <p class="sp">{{ $t('key1005273') }} </p>
      <p class="sp">{{ $t('key1005274') }}</p>
      <p class="sp1">① {{ $t('key1005275') }}</p>
      <p class="sp1">{{ $t('key1005276') }}//www.tongtool.com/）授权开通。</p>
      <p class="sp1">{{ $t('key1005277') }}</p>
      <p class="sp1">② {{ $t('key1005278') }}</p>
      <p class="sp1">{{ $t('key1005279') }}</p>
      <p class="sp1">③ {{ $t('key1005280') }}</p>
      <p class="sp1">{{ $t('key1005281') }}</p>
      <p class="tit">2. {{ $t('key1005282') }}</p>
      <p class="sp">{{ $t('key1005283') }}</p>
      <p class="sp">{{ $t('key1005274') }}</p>
      <p class="sp1">① {{ $t('key1005284') }}</p>
      <p class="sp1">② {{ $t('key1005285') }}</p>
      <p class="tit">3. {{ $t('key1005286') }}</p>
      <p class="sp">{{ $t('key1005287') }}</p>
      <p class="sp">{{ $t('key1005274') }}</p>
      <p class="sp1"> ① {{ $t('key1005288') }}</p>
      <p class="sp1">② {{ $t('key1005289') }}</p>
    </div>
  </div>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';

export default {
  name: 'businessIntroduce',
  mixins: [Mixin],
  data () {
    return {
      breadMenuData: [{
        path: '/index',
        name: alias2f085dd487c1465383484787b29fef2c.t('key1005158')
      },{
        path: '/businessIntroduce',
        name: alias2f085dd487c1465383484787b29fef2c.t('key1005290')
      }]
    };
  },
  created () {

  },
  props: {},
  watch: {},
  methods: {}
};
</script>

<style scoped lang="less">
.box {
  width: 1200px;
  margin: 0 auto;
  .navigation {
    padding: 10px;
  }
  .business-introduce-box {
    background-color: #ffffff;
    margin-bottom: 20px;
    padding: 24px 32px 24px 32px;
    line-height: 32px;
    color: #000000;
    .tit {
      font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
      font-weight: 700;
      margin-top: 30px;
    }
    .tit:first-child{
      margin-top: 0;
    }
    .sp1 {
      padding-left: 18px;
    }
  }
}
</style>
